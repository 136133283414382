import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import Navigation from "sections/Navigation"
import Footer from "sections/Footer-classic"

import Privacy from '../../../mySections/BlogContent/index'
import PrivacyContent from '../../../mySections/TermOfUseData/en/termE_data_todo'

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

const appName_en = "Next To Do"

const Terms_Todo_En = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Next To Do Terms" />
      <Navigation pageName="404" />
      <Privacy
        title={appName_en + " " + "Terms of Use"}
        content={PrivacyContent}
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)
export default Terms_Todo_En