import React from "react";

import { Container} from "reusecore/Layout";
import SectionTitle from "reusecore/SectionTitle";

import BannerSectionWrapper from "./blogContent.style";

const blogContent = (props) => {
  return (
    <BannerSectionWrapper>
      <Container fullWidthSM>
            <SectionTitle
              className="section-title"
              UniWidth="100%"
            >
              <h1>
              {props.title}
              </h1>
              
              <h4 dangerouslySetInnerHTML={{ __html: props.content}} />
            </SectionTitle>
      </Container>
    </BannerSectionWrapper>
  );
};

export default blogContent;
