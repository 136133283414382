import React from "react";
import { Link } from "gatsby";

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaPinterestP,
  FaInstagram
} from "react-icons/fa";

import { Container, Row, Col } from "reusecore/Layout";
import Button from "reusecore/Button";
import Particle from "reusecore/Particle";

import logo from "assets/images/app/logo/logo2.png";

import FooterBGTwo from "assets/images/app/footer/footer-particle-two.png";

import FotterWrapper from "./footer.style";

const link_fb = "http://www.baidu.com"
const link_twi = "http://www.baidu.com"
const link_ig = "http://www.baidu.com"
const link_lk = "http://www.baidu.com"
const link_pin = "http://www.baidu.com"

const FooterClassic = (props) => {
  return (
    <FotterWrapper id="bottomPart">
      <img src={FooterBGTwo} alt="img" className="section__particle one" />
      <Particle />
      <Container>
        <Row>
          <Col xs={12} sm={12} lg={3}>
            <div className="footer-widgets first">
              <Link to="/" className="footer-logo">
                <img src={logo} alt="logo" />
              </Link>
              {/* <form className="subscribe-form">
                <input type="text" placeholder="Search here..." />
                <Button>
                  <FaEnvelope />
                </Button>
              </form> */}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={3}>
            <div className="footer-widgets footer-main-info">
              <h3 className="widget-title">Apps</h3>
              <ul className="widget-catagory">
                <li>
                  <Link to="/NextToDo">Next To Do</Link>
                </li>
                <li>
                  <Link to="/MiniPomo">Mini Pomo</Link>
                </li>
                <li>
                  <Link to="/DrinkReminder">Drink Reminder</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={3}>
            <div className="footer-widgets footer-main-info">
              <h3 className="widget-title">Others</h3>
              <ul className="widget-catagory">
                {!props.privacyLink || 0 === props.privacyLink.length ? 
                  <li>
                    Coming Soon
                  </li>
                  :
                  <li>
                    <Link to={props.privacyLink}>Privacy</Link>
                  </li>
                }
                {!props.termsLink || 0 === props.termsLink.length ? 
                  <li>
                  
                  </li>
                  :
                  <li>
                    <Link to={props.termsLink}>Terms of Use</Link>
                  </li>
                }
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={3}>
            <div className="footer-widgets footer-main-media">
              <h3 className="widget-title">Contact</h3>
              <h4>
                <a href="mailto:cigamtech@outlook.com">cigamtech@outlook.com</a>
              </h4>
              {/* <ul className="social">
                <li>
                  <a href={link_fb} target="_blank">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href={link_twi} target="_blank">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href={link_ig} target="_blank">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href={link_lk} target="_blank">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li>
                  <a href={link_pin} target="_blank">
                    <FaPinterestP />
                  </a>
                </li>
              </ul> */}
              <p className="copyright-text">
                Copyright &#169; 2020 Design By Cigam Tech
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FotterWrapper>
  );
};

export default FooterClassic;
