import styled from 'styled-components';

const BlogContentWrapper = styled.section`
    padding: 30px 0 80px 0;
    margin: 80px 0 0 0;
    .img-404 {
        margin: 0 auto;
        display: block;
        width: 50%;
        height: auto;
    }
    .section-title{
        margin-top: 30px;
        h1{
            font-weight: 600;
            font-size: 30px;
            margin: 0 0 0 0; 
            text-align: center;
        }
        h4 {
            margin-top: 0px;
            font-weight: 400;
            font-size: 20px;
            text-align: left;
            span{
                font-size: 25px;
                font-weight: 600;
                color: #061178;
            }
            strong {
                font-size: 25px;
                font-weight: 600;
                color: #061178;
            }
        }
    }
    .banner-btn{
        margin: 50px auto 0 auto;
        display: block;
        background: #8A57DE;
        color: #fff;
        &:hover{
            background: #EEE6FA;
            color: #8A57DE;
        }
    }
    @media only screen and (max-width: 912px) {
        margin: 30px 0 0 0;
        background-size: 57%;
        padding: 120px 0 60px 0;
        .section-title{
            h1{
                font-size: 32px;
                line-height: 42px;
                margin: 0 0 25px 0;
            }
            h4{
                font-size: 16px;
            }
        }
        p{ 
            margin: 0 0 30px 0;
        }
        .banner-btn{
            min-width: 140px;
        }
        .banner-btn+.banner-btn{
            margin-left: 17px;
        }
     }
     @media only screen and (max-width: 760px) {
        padding: 130px 0 60px 0;
     }
     @media only screen and (max-width: 568px) {
        background: none;
        .banner-btn{
            min-width: 160px;
        }
     }
     @media only screen and (max-width: 480px) {
        padding: 120px 0 100px 0;
        .section-title{
            h1{
                font-size: 35px;
                line-height: 50px;
            }
        }
         .vintage-box{
             &:before{
                 content: none;
             }
         }
     }
     @media only screen and (max-width: 380px) {
       
        .section-title{
            h1{
                font-size: 30px;
                line-height: 46px;
                margin: 0 0 30px 0;
            }
            h4{
                font-size: 15px;
            }
        }
        .banner-btn{
            font-size: 14px;
            min-width: 127px;
            padding: 14px 12px; 
        }
        .banner-btn+.banner-btn{
            margin-left: 15px;
        }
     }
     @media only screen and (max-width: 330px) {
        .section-title{
            h1{
                font-size: 23px;
                line-height: 35px;
            }
        }
     }
     
`

export default BlogContentWrapper;